import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/SEO"

import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { helmetJsonLdProp } from "react-schemaorg"

const BlogPost = ({
  data: {
    mdx: {
      headings,
      embeddedImagesRemote,
      frontmatter: {
        title,
        slug,
        date,
        date_modified,
        embeddedImagesLocal,
        featuredImage,
        author,
      },
      body,
    },
  },
}) => {
  const jsonLd = helmetJsonLdProp({
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: title,
    datePublished: date,
    dateModified: date_modified,
    keywords: ["fattura", "forfettario"],
    author: [
      {
        "@type": "Person",
        name: author,
        url: `https://fidofattura.it${slug}`,
      },
    ],
    image: [featuredImage.publicURL],
  })

  return (
    <Layout>
      <Seo title={title} script={jsonLd} />
      <div className="px-8">
        <h1 className="font-bold text-5xl pb-4 dark:text-[#DCFCF3]">{title}</h1>
        <div>
          <span>{author}</span>
          <span>{date}</span>
        </div>
        <div className="flex items-center justify-center flex-row">
          <GatsbyImage image={getImage(featuredImage)} alt="copertina" />
        </div>
      </div>

      <div className="px-8 article-content">
        <MDXProvider>
          <MDXRenderer
            remoteImages={embeddedImagesRemote}
            localImages={embeddedImagesLocal}
            headings={headings}
          >
            {body}
          </MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query Post($postId: String!) {
    mdx(id: { eq: $postId }) {
      headings {
        depth
        value
      }
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP, AUTO])
        }
      }
      frontmatter {
        title
        slug
        date
        date_modified
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [WEBP, AUTO])
          }
        }
        author
        featuredImage {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      body
    }
  }
`
